import React, { useState, useEffect } from "react";

export default function ViewTCMOdal({ setOpenModal, onView, initialData }) {
  const [formData, setFormData] = useState(initialData);

  useEffect(() => {
    // Call handleView when the modal is opened
    handleView();
  }, []); // Empty dependency array ensures this effect runs only once when the component mounts

  const handleView = () => {
    onView(formData);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <>
      <div className="fixed inset-0 z-10 overflow-y-auto ">
        <div
          className="fixed inset-0 w-full h-full bg-black opacity-40"
          onClick={() => setOpenModal(false)}
        ></div>
        <div className="flex min-h-screen px-4 py-8">
          <div className="relative w-[40rem] max-w-lg-[32rem]  p-4 mx-auto bg-white rounded-md shadow-lg">
            <div className="flex justify-end">
              <button
                className="text-gray-500 hover:text-gray-700"
                onClick={handleClose}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div className="mt-3 sm:flex ">
              <div className="mt-2 sm:ml-4 sm:text-left w-full md:col-2">
                <h4 className="text-xl font-semibold text-gray-800 mb-4">
                  View Tiffin Center
                </h4>
                <div className="text-[15px] leading-relaxed text-gray-500">
                  <form className="text-black">
                    <div className="flex flex-wrap -mx-2 mb-4 text-black">
                      <div className="w-full sm:w-1/4 px-2">
                        <label className="block">Owner Name:</label>
                        <input
                          type="text"
                          name="tcOwnerName"
                          value={formData.tcOwnerName}
                          className="w-full border rounded-md p-2"
                          readOnly
                        />
                      </div>
                      <div className="w-full sm:w-1/4 px-2">
                        <label className="block">Tiffin Center Name:</label>
                        <input
                          type="text"
                          name="tiffinName"
                          value={formData.tiffinName}
                          className="w-full border rounded-md p-2"
                          readOnly
                        />
                      </div>
                      <div className="w-full sm:w-1/4 px-2">
                        <label className="block">Tiffin Id:</label>
                        <input
                          type="text"
                          name="tiffinId"
                          value={formData.tiffinId}
                          className="w-full border rounded-md p-2"
                          readOnly
                        />
                      </div>
                      <div className="w-full sm:w-1/4 px-2">
                        <label className="block">Email:</label>
                        <input
                          type="text"
                          name="email"
                          value={formData.email}
                          className="w-full border rounded-md p-2"
                          readOnly
                        />
                      </div>

                      <div className="w-full sm:w-1/4 px-2">
                        <label className="block">Password:</label>
                        <input
                          type="text"
                          name="password"
                          value={formData.password}
                          className="w-full border rounded-md p-2"
                          readOnly
                        />
                      </div>
                      <div className="w-full sm:w-1/4 px-2">
                        <label className="block">Contact No:</label>
                        <input
                          type="text"
                          name="phone"
                          value={formData.phone}
                          className="w-full border rounded-md p-2"
                          readOnly
                        />
                      </div>
                      <div className="w-full sm:w-1/4 px-2">
                        <label className="block">Address:</label>
                        <input
                          type="text"
                          name="address"
                          value={formData.address}
                          className="w-full border rounded-md p-2"
                          readOnly
                        />
                      </div>
                      <div className="w-full sm:w-1/4 px-2">
                        <label className="block">Pincode:</label>
                        <input
                          type="text"
                          name="pincode"
                          value={formData.pincode}
                          className="w-full border rounded-md p-2"
                          readOnly
                        />
                      </div>

                      <div className="w-full sm:w-1/4 px-2">
                        <label className="block">Pincode 1:</label>
                        <input
                          type="text"
                          name="pincode1"
                          value={formData.pincode1}
                          className="w-full border rounded-md p-2"
                          readOnly
                        />
                      </div>
                      <div className="w-full sm:w-1/4 px-2">
                        <label className="block">Pincode2:</label>
                        <input
                          type="text"
                          name="pincode2"
                          value={formData.pincode2}
                          className="w-full border rounded-md p-2"
                          readOnly
                        />
                      </div>
                      <div className="w-full sm:w-1/4 px-2">
                        <label className="block">Pincode3:</label>
                        <input
                          type="text"
                          name="pincode3"
                          value={formData.pincode3}
                          className="w-full border rounded-md p-2"
                          readOnly
                        />
                      </div>
                      <div className="w-full sm:w-1/4 px-2">
                        <label className="block">Pincode4:</label>
                        <input
                          type="text"
                          name="pincode4"
                          value={formData.pincode4}
                          className="w-full border rounded-md p-2"
                          readOnly
                        />
                      </div>

                      <div className="w-full sm:w-1/4 px-2">
                        <label className="block">Subscription Plan:</label>
                        <input
                          type="text"
                          name="subscriptionPlan"
                          value={formData.subscriptionPlan}
                          className="w-full border rounded-md p-2"
                          readOnly
                        />
                      </div>
                      <div className="w-full sm:w-1/4 px-2">
                        <label className="block">Start Date:</label>
                        <input
                          type="text"
                          name="startDate"
                          value={formData.startDate}
                          className="w-full border rounded-md p-2"
                          readOnly
                        />
                      </div>
                      <div className="w-full sm:w-1/4 px-2">
                        <label className="block">End Date:</label>
                        <input
                          type="text"
                          name="endDate"
                          value={formData.endDate}
                          className="w-full border rounded-md p-2"
                          readOnly
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
