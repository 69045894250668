import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AttendModal from '../admin/AttendModal';

const TiffinDetails = () => {
    const [deliveryOrders, setDeliveryOrders] = useState([]);
    const [tcDetailsMap, setTcDetailsMap] = useState({});
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [isAttendanceModalOpen, setIsAttendanceModalOpen] = useState(false);
    const userData = JSON.parse(localStorage.getItem("user"));

    useEffect(() => {
        if (userData) {
            // Fetch all delivery orders
            axios
                .get(`/api/v1/tfc/getod/${userData._id}`)
                .then((response) => {
                    const data = response.data;
                    if (data.success) {
                        setDeliveryOrders(data.data);

                        // Fetch customer details for each order
                        data.data.forEach((order) => {

                            fetchTcDetails(order.orderId);
                        });
                    } else {
                        console.error('Failed to retrieve delivery orders');
                    }
                })
                .catch((error) => {
                    console.error('Error fetching delivery orders', error);
                });
        }
    }, [userData]);

    const handleClick = (orderId) => {
        // Set the selected order and open the attendance modal
        setSelectedOrder(orderId);
        setIsAttendanceModalOpen(true);
    };


    const fetchTcDetails = async (orderId) => {
        try {
            // Fetch customer details based on orderId
            const response = await axios.get(`/api/v1/tfc/gettc/${orderId}`);
            const data = response.data;

            if (data.success) {
                // Update tc details with customer details for the specific order
                setTcDetailsMap((prevMap) => ({
                    ...prevMap,
                    [orderId]: data.data,
                }));
            } else {
                console.error('Failed to retrieve tc details');
            }
        } catch (error) {
            console.error('Error fetching tc details', error);
        }
    };

    return (
        <div className="container mx-auto mt-8 p-1">
            <h1 className="text-4xl font-semibold text-center mb-6 underline text-violet-800">
                Tiffin Details
            </h1>

            {deliveryOrders && deliveryOrders.length > 0 && (
                <div className="overflow-x-auto">
                    <table className="w-full border-collapse bg-white shadow-lg rounded-lg mt-4">
                        <thead>
                            <tr className="bg-gradient-to-r from-violet-600 to-violet-600 text-white">
                                <th className="py-3 px-4 text-left whitespace-pre-wrap">S no</th>
                                <th className="py-3 px-4 text-left whitespace-pre-wrap">Date</th>
                                {/* <th className="py-3 px-4 text-left whitespace-pre-wrap">Tiffin Center Name</th>
                                <th className="py-3 px-4 text-left whitespace-pre-wrap">Tiffin Center Id</th> */}
                                <th className="py-3 px-4 text-left whitespace-pre-wrap">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {deliveryOrders.map((order, index) => (
                                <tr key={order._id}>
                                    <td className="py-3 px-4 text-left whitespace-pre-wrap">{index + 1}</td>
                                    <td className="py-3 px-4 text-left whitespace-pre-wrap">{order.createdAt}</td>
                                    {tcDetailsMap[order.orderId] && (
                                        <>
                                            {/* <td className="py-3 px-4 text-left whitespace-pre-wrap">
                                                {tcDetailsMap[order.orderId].tiffinName}
                                            </td>
                                            <td className="py-3 px-4 text-left whitespace-pre-wrap">
                                                {tcDetailsMap[order.orderId].tiffinId}
                                            </td> */}
                                        </>
                                    )}

                                    <td
                                        className="py-3 px-4 text-left cursor-pointer text-blue-500 hover:underline"
                                        onClick={() => handleClick(order.orderId)}
                                    >
                                        View
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}

            {/* Attendence Modal */}
            <AttendModal
                isOpen={isAttendanceModalOpen}
                onClose={() => setIsAttendanceModalOpen(false)}
                orderId={selectedOrder}
            />
        </div>
    );
};

export default TiffinDetails;
