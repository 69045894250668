import React, { useEffect, useState } from "react";
import axios from "axios";
import OrderInvoice from "./OrderInvoice";
import toast, { Toaster } from "react-hot-toast";
import AttendModal from "./AttendModal";
const AllCostomer = () => {
  const [d, setData] = useState([]);
  const [isAttendanceModalOpen, setIsAttendanceModalOpen] = useState(false);
  const [tfdata, setTFD] = useState([]);
  const [userData, setUserData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [delmodal, setdelModal] = useState(false);
  const [selectedBuyer, setSelectedBuyer] = useState("");
  const [selectedOrder, setSelectedOrder] = useState("");
  const [selectedTiffin, setSelectedTiffin] = useState("");
  const [selectedDelboy, setSelectedDelBoy] = useState("");
  const [del, setDel] = useState([]);
  const [isInvoiceModalOpen, setIsInvoiceModalOpen] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [searchId, setSearchId] = useState("");
  const [priya, setPriya] = useState("");
  const getAllOrder = async (id = "") => {
    try {
      const url = id
        ? `/api/v1/payment/get-searchOrder/${id}`
        : "/api/v1/payment/list-orders";
      const res = await axios.get(url);
      console.log(res.data);
      const { data } = res;
      console.log(data, "data all order");
      console.log(Array.isArray(data));
      setData(data);
    } catch (error) {
      console.error(error);
    }
  };

  const getUser = async (id, index) => {
    try {
      const { data } = await axios.get(`/api/v1/auth/get-sUser/${id}`);
      if (data) {
        setUserData((prevUserData) => {
          const updatedUserData = [...prevUserData];
          updatedUserData[index] = data.user;
          return updatedUserData;
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllOrder();
  }, []);

  useEffect(() => {
    // Fetch user data for each item's buyer
    if (Array.isArray(d)) {
      d.forEach((item, index) => {
        getUser(item.buyer, index);
      });
    }
  }, [d]);

  const getAllTf = async () => {
    try {
      const dt = await axios.get("/api/v1/auth/get-alltcenter");
      const { data } = dt;
      console.log(data, "tfdata");
      setTFD(data);
    } catch (error) {
      console.error(error);
    }
  };
  // getting all delivery boy
  const getAlldel = async () => {
    try {
      const del = await axios.get("/api/v1/auth/get-delboy");
      const { data } = del;
      setDel(data);
      console.log(data, "all delivery boy getting successfully...!");
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllTf();
    getAlldel();
  }, []);

  const paybtn = (order) => {
    setIsAttendanceModalOpen(true);
    setSelectedOrder(order);
  };

  const handleSearch = () => {
    getAllOrder(searchId);
  };
  const handleEnterKey = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };
  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const handleSortAscending = () => {
    // Sort data in ascending order based on date
    const sortedData = [...d].sort(
      (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
    );
    setData(sortedData);
  };

  const handleSortDescending = () => {
    // Sort data in descending order based on date
    const sortedData = [...d].sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    );
    setData(sortedData);
  };
  const handleFilterAndSort = () => {
    // Filter data based on the selected date range
    const filteredData = d.filter(
      (item) =>
        new Date(item.createdAt) >= new Date(startDate) &&
        new Date(item.createdAt) <= new Date(endDate)
    );

    // Sort filtered data in ascending order based on date
    const sortedData = [...filteredData].sort(
      (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
    );

    setData(sortedData);
  };
  return (
    <>
      <h1 className="text-2xl font-bold mb-4 text-center underline text-purple-500">
        Delivery Details
      </h1>
      <div>
        <div>
          <div className="mb-4 flex flex-col md:flex-row">
            <div className="mb-2 md:mb-0">
            <input
              type="text"
              placeholder="search by ordr Id"
              className="p-2 mr-2 border border-purple-900"
              value={searchId}
              onChange={(e) => setSearchId(e.target.value)}
              onKeyUp={handleEnterKey}
            />
            <button
              className="bg-blue-500 text-white px-4 py-2 rounded "
              onClick={handleSearch}
            >
              Search
            </button>
          </div>
          </div>
          {/* for date start here  */}
          <div className="mt-4 flex flex-wrap items-center">
        <div>
        <label htmlFor="str" className="font-bold text-purple-500">
              Start Date
            </label>
            <input
              type="date"
              className="m-2 rounded-xl border-2 border-slate-500"
              value={startDate}
              onChange={handleStartDateChange}
            />
        </div>

           <div>
           <label htmlFor="edr" className="font-bold text-purple-500">
              End Date
            </label>
            <input
              type="date"
              className="m-2 rounded-xl border-2 border-slate-500"
              value={endDate}
              onChange={handleEndDateChange}
            />
           </div>
            <div className="mt-2 flex flex-wrap items-center">
            <button
              onClick={handleFilterAndSort}
              className="bg-blue-500 text-white py-2 ml-3 px-4 rounded hover:bg-yellow-600 w-full md:w-auto mt-2"
            >
              Apply
            </button>


            <button
              onClick={handleSortAscending}
              className="bg-green-500 text-white py-2 ml-3 px-4 rounded hover:bg-yellow-600 w-full md:w-auto mt-2"
            >
              Ascending
            </button>

            <button
              onClick={handleSortDescending}
              className="bg-red-500 text-white py-2 ml-3 px-4 rounded hover:bg-yellow-600 w-full md:w-auto mt-2"
            >
              Descending
            </button>
          </div>

          {/* for data end her  */}
        </div>
      </div>
      </div>

      <div className="w-full overflow-x-auto mx-auto bg-white mt-2">
        <table className="min-w-full table-auto border-2 border-gray-500">
          <thead className="">
            <tr className="text-center bg-purple-500 text-white">
              <th className="py-2 px-1 border-2 w-auto sm:w-auto md:w-auto lg:w-auto xl:w-auto">
                Date
              </th>
              <th className="py-2 px-1 border-2 w-auto sm:w-auto md:w-auto lg:w-auto xl:w-auto">
                Customer Name
              </th>
              <th className="py-2 px-1 border-2 w-auto sm:w-auto md:w-auto lg:w-auto xl:w-auto">
                Email
              </th>
              <th className="py-2 px-1 border-2 w-auto sm:w-auto md:w-auto lg:w-auto xl:w-auto">
                Address
              </th>
              <th className="py-2 px-1 border-2 w-auto sm:w-auto md:w-auto lg:w-auto xl:w-auto">
                Contact Number
              </th>

              <th className="py-2 px-1 border-2 w-auto sm:w-auto md:w-auto lg:w-auto xl:w-auto">
                Pincode
              </th>

              <th className="py-2 px-1 border-2 w-auto sm:w-auto md:w-auto lg:w-auto xl:w-auto">
                OrderId
              </th>
              <th className="py-2 px-1 border-2 w-auto sm:w-auto md:w-auto lg:w-auto xl:w-auto">
                Tiffiin Schedule
              </th>
              <th className="py-2 px-1 border-2 w-auto sm:w-auto md:w-auto lg:w-auto xl:w-auto">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(d) &&
              d.map((item, index) => (
                <tr
                  key={index}
                  className="font-serif bg-purple-300 text-black border-1"
                >
                  <td className="py-1 text-center">
                    {new Date(item.createdAt).toLocaleDateString("en-GB")}
                  </td>
                  <td className="py-1 text-center">{userData[index]?.name}</td>
                  <td className="py-1 text-center">{userData[index]?.email}</td>
                  <td className="py-1 text-center">
                    {userData[index]?.address}
                  </td>
                  <td className="py-1 text-center">{userData[index]?.phone}</td>
                  <td className="py-1 text-center">
                    {userData[index]?.pincode}
                  </td>
                  <td className="py-1 text-center">{item.razorpay.orderId}</td>
                  <td className="py-1 text-center">
                    {item.item.map((orderItem, itemIndex) => (
                      <div key={itemIndex}>{orderItem.schedule}</div>
                    ))}
                  </td>
                  <td className="py-1 text-center">
                    <button
                      onClick={() => paybtn(item._id)}
                      className=" hover:text-green-500  text-blue-500 py-2"
                    >
                      view Delivery Details
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <>
        <AttendModal
          isOpen={isAttendanceModalOpen}
          onClose={() => setIsAttendanceModalOpen(false)}
          orderId={selectedOrder}
        />
      </>

      <Toaster />
    </>
  );
};

export default AllCostomer;
