import React, { useState, useEffect } from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Modal from "react-modal";
import axios from "axios";

Modal.setAppElement("#root");

const OrderInvoice = ({ isOpen, onClose, orderData }) => {
    console.log(orderData)
    const [items, setItems] = useState([
        { description: "", price: "", quantity: "" },
    ]);
    const [total, setTotal] = useState("");

    const [invoiceData, setInvoiceData] = useState({
        invoiceNo: "",
        invoiceDate: "",
        item: "",
    });
    const [userData, setUserData] = useState({
        contact: "",
        name: "",
        address: "",
    });

    const formatDate = (dateString) => {
        const options = { day: "2-digit", month: "2-digit", year: "numeric" };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    useEffect(() => {
        //  console.log(orderData, "orderrrrrrrrrrrrrrrrrrrrrrrr");
        const fetchDataForItems = async () => {
            if (orderData) {
                const updatedItems = await Promise.all(
                    orderData.item.map(async (item, index) => {
                        const response = await axios.get(
                            `/api/v1/item/get-singleItemById/${item.item}`
                        );
                        const res = await axios.get(
                            `/api/v1/auth/get-sUser/${orderData.buyer}`
                        );
                        // console.log(orderData.item[index].price, "schedule");

                        //console.log(res, "userdataaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa");
                        const dkp = res.data.user;
                        setUserData({
                            contact: dkp.phone,
                            name: dkp.name,
                            address: dkp.address,
                        });

                        // console.log(
                        //   orderData.buyer,
                        //   "ddddddddddddddddddddddddddddddddddddddd"
                        // );

                        const itemData = response.data.item;

                        setInvoiceData({
                            invoiceNo: orderData.invoiceNo || "",
                            invoiceDate: formatDate(orderData.createdAt || ""),
                            item: orderData.item,
                        });
                        return {
                            schedule: orderData.item[index].schedule,

                            price: orderData.item[index].price,
                            /* quantity: itemData.quantity,
                            discount: itemData.discount, */
                        };
                    })
                );

                setItems(updatedItems);
                calculateTotals();
            }
        };

        fetchDataForItems();
    }, [isOpen, orderData]);

    const handleTableChange = (index, field, value) => {
        const updatedItems = [...items];
        updatedItems[index][field] = value;

        if (field === "qty" || field === "rate") {
            const price = parseFloat(updatedItems[index]["price"]) || 0;
            updatedItems[index] = price;
        }
    };
    useEffect(() => {
        calculateTotals();
    }, [items]);
    // Add this function to handle discount change

    const calculateTotals = () => {
        let totalAmount = 0;

        items.forEach((item) => {
            const amount = parseFloat(item.price) || 0;
            totalAmount += amount;
        });

        const netTotalValue = totalAmount;
        setTotal(netTotalValue);
    };

    useEffect(() => {
        if (isOpen && orderData) {
            calculateTotals();
        }
    }, [isOpen, orderData]);

    const downloadPDF = () => {
        const doc = new jsPDF();

        doc.setFontSize(18);
        doc.text(
            "Manvika Consultancy Services Private Limited",
            doc.internal.pageSize.width / 2,
            20,
            { align: "center" }
        );

        const invoiceDate = document.getElementById("invoice-date").value;
        const invoiceNo = document.getElementById("invoice-number").value;
        const Bil_to = document.getElementById("bill-to").value;
        const address = document.getElementById("address").value;
        const contact = document.getElementById("contact").value;

        doc.setFontSize(12);
        doc.text("Invoice Date: " + invoiceDate, 20, 40);
        doc.text("Invoice No: " + invoiceNo, 20, 50);
        doc.text("Bil To: " + Bil_to, 20, 60);
        doc.text("Address: " + address, 20, 70);
        doc.text("Contact: " + contact, 20, 80);

        doc.autoTable({
            head: [["Description", "Rate", "Amount"]],
            body: items.map((item) => [
                { content: item.schedule, styles: { fontSize: 10 } },
                { content: "Rs " + item.price, styles: { fontSize: 10 } },
                { content: "Rs " + item.price, styles: { fontSize: 10 } },
            ]),
            startY: 100,
            headStyles: { fillColor: "#673AB7", textColor: "#FFFFFF" },
            bodyStyles: { textColor: "#000000" },
            alternateRowStyles: { fillColor: "#D1C4E9" },
            styles: { fontSize: 14 },
        });

        // Add Total on the right bottom side
        const pageHeight = doc.internal.pageSize.height;
        doc.text(
            "Total: Rs " + total,
            doc.internal.pageSize.width - 50,
            pageHeight - 150
        );

        // Save the PDF file
        doc.save("invoice.pdf");
    };

    useEffect(() => {
        calculateTotals();
    }, []);

    return (
        <>
            {" "}
            {isOpen && (
                <Modal
                    isOpen={isOpen}
                    onRequestClose={onClose}
                    contentLabel="Edit Modal"
                    style={{
                        content: {
                            width: "80%",
                            maxWidth: "1000px",
                            maxHeight: "80vh",
                            margin: "auto",
                            padding: "20px",
                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                            borderRadius: "8px",
                            overflowY: "auto",
                            position: "relative",
                            top: "50%",
                            transform: "translateY(-50%)",
                            border: "2px solid #8A2BE2",
                        },
                    }}
                >
                    <div className="flex justify-end">
                        <button
                            className="text-gray-500 hover:text-gray-700"
                            onClick={onClose}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        </button>
                    </div>

                    <div className="flex justify-center items-center">
                        <form className="w-full max-w-6xl border border-gray-300 p-6 rounded-md">
                            <h1 className=" underline text-violet-800 text-center p-0 m-0 text-3xl my-0">
                                Customer Invoice
                            </h1>

                            <h1 className=" text-violet-700 text-center p-2 m-2 text-2xl">
                                Manvika Consultancy Services private Limited
                            </h1>
                            <br />

                            <div className="flex flex-wrap -mx-3 mb-6">
                                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label
                                        htmlFor="invoice-date"
                                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                    >
                                        Date:
                                    </label>
                                    <input
                                        className="appearance-none block w-full bg-white-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                        id="invoice-date"
                                        value={invoiceData.invoiceDate}
                                        type="text"
                                        placeholder="Enter date"
                                    />
                                </div>
                                <div className="w-full md:w-1/2 px-3">
                                    <label
                                        htmlFor="invoice-number"
                                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                    >
                                        Invoice No:
                                    </label>
                                    <input
                                        className="appearance-none block w-full bg-white-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        id="invoice-number"
                                        type="text"
                                        value={invoiceData.invoiceNo}
                                        placeholder="Enter invoice number"
                                    />
                                </div>
                            </div>

                            <div className="flex flex-wrap -mx-3 mb-3">
                                <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                                    <label
                                        htmlFor="bill-to"
                                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                    >
                                        Bill To:
                                    </label>
                                    <input
                                        className="appearance-none block w-full bg-white-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        id="bill-to"
                                        type="text"
                                        value={userData.name}
                                        placeholder="Enter billing information"
                                    />
                                </div>
                                <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                                    <label
                                        htmlFor="address"
                                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                    >
                                        Address:
                                    </label>
                                    <input
                                        className="appearance-none block w-full bg-white-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        id="address"
                                        type="text"
                                        value={userData.address}
                                        placeholder="Enter address"
                                    />
                                </div>
                                <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                                    <label
                                        htmlFor="contact"
                                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                    >
                                        Contact:
                                    </label>
                                    <input
                                        className="appearance-none block w-full bg-white-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        id="contact"
                                        type="text"
                                        value={userData.contact}
                                        placeholder="Enter contact information"
                                    />
                                </div>
                            </div>

                            <br />
                            <div className="relative overflow-x-auto">
                                <table className="w-full text-sm text-left rtl:text-right text-black-800">
                                    <thead className="text-xs text-gray-700 uppercase bg-violet-500 dark:text-black-800">
                                        <tr>
                                            <th scope="col" className="px-6 py-3">
                                                Schedule
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                Rate
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                Amount
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {items.map((item, index) => (
                                            <tr
                                                key={index}
                                                className="bg-white border-b dark:bg-violet-400 dark:border-gray-300"
                                            >
                                                <th
                                                    scope="row"
                                                    className="px-6 py-4 font-medium text-black-900 whitespace-nowrap dark:text-black"
                                                >
                                                    <input
                                                        value={item.schedule}
                                                        onChange={(e) =>
                                                            handleTableChange(
                                                                index,
                                                                "description",
                                                                e.target.value
                                                            )
                                                        }
                                                        className="block w-full bg-white-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                                        placeholder="Description"
                                                    />
                                                </th>

                                                <td className="px-6 py-4 font-medium text-black-700 whitespace-nowrap dark:text-black">
                                                    <input
                                                        value={item.price}
                                                        onChange={(e) =>
                                                            handleTableChange(index, "price", e.target.value)
                                                        }
                                                        readOnly
                                                        className="appearance-none w-16 md:w-32 lg:w-48 block bg-white-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                                        placeholder="Rate"
                                                    />
                                                </td>
                                                <td className="px-6 py-4 font-medium text-black-700 whitespace-nowrap dark:text-black">
                                                    <input
                                                        className="appearance-none w-20 md:w-32 lg:w-48 block bg-white-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                                        value={item.price}
                                                        onChange={(e) =>
                                                            handleTableChange(index, "price", e.target.value)
                                                        }
                                                        readOnly
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            <br />
                            <div className="float-right flex">
                                <div className="w-full md:w-1/2 px-3">
                                    <label
                                        htmlFor="subTotal1"
                                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                    >
                                        Sub Total :
                                    </label>
                                    <input
                                        className="appearance-none block w-full bg-white-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        id="subTotal"
                                        type="text"
                                        value={total}
                                        readOnly
                                    />
                                </div>
                                <br />

                                <br />
                            </div>
                            <div className="text-center mt-4">
                                <button
                                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                                    onClick={downloadPDF}
                                >
                                    Download Invoice
                                </button>
                            </div>
                        </form>
                    </div>
                </Modal>
            )}
        </>
    );
};

export default OrderInvoice;
