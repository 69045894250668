import React, { useState, useEffect } from "react";

import CustomerCart from "./CustomerCart";
import CustomerOrder from "./CustomerOrder";
import CustomerProfile from "./CustomerProfile";
import TiffinDetails from "./TiffinDetails";
import { AiFillHome } from "react-icons/ai";
import { BsFillPersonFill } from "react-icons/bs";

import dashbg from "../../assets/images/dashbg.png";
import { IoArrowBackSharp } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import { FaCcAmazonPay, FaShoppingBag } from "react-icons/fa";

import { RiLogoutCircleRFill } from "react-icons/ri";
import { FaShoppingCart } from "react-icons/fa";
// import TiffinCenterPayment from "../tcmanager/TiffinCenterPayment";

const CustomerDash = () => {
  const [open, setOpen] = useState(true);
  const [selectedContent, setSelectedContent] = useState("dashboard");
  const userData = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();
  const handleLogout = async () => {
    try {
      localStorage.clear();
      navigate("/");
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const handleMenuItemClick = (itemId) => {
    setSelectedContent(itemId);
    if (open) {
      setOpen(!open);
    }
  };
  useEffect(() => {
    if (!userData || userData.role !== 0) {
      navigate("/");
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="flex " style={{ position: "relative", Width: "80%" }}>
      <div
        className={` ${
          open ? "md:w-[20rem] w-[21rem] " : "w-20 "
        } bg-[conic-gradient(at_top,_var(--tw-gradient-stops))] p-3  from-slate-900 via-purple-900 to-slate-900 h-screen  pt-4 fixed duration-300`}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1"
          stroke="white"
          className={`absolute cursor-pointer -right-3 top-5 w-7 mr-4 border-1 border-blue  ${
            !open ? "rotate-180" : ""
          }`}
          onClick={() => setOpen(!open)}
        >
          {open ? (
            <>
              {" "}
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 6L18 18"
              />
            </>
          ) : (
            <>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </>
          )}
        </svg>

        <div className="flex gap-x-4 m-2 ">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className={`cursor-pointer duration-500 bg-gradient-to-r from-violet-200 to-violet-700 rounded-full h-20 ml-16 w-20 ${
              !open && " w-9 h-8 m-0 mt-4"
            }`}
          >
            <path
              fill-rule="evenodd"
              d="M18.685 19.097A9.723 9.723 0 0021.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 003.065 7.097A9.716 9.716 0 0012 21.75a9.716 9.716 0 006.685-2.653zm-12.54-1.285A7.486 7.486 0 0112 15a7.486 7.486 0 015.855 2.812A8.224 8.224 0 0112 20.25a8.224 8.224 0 01-5.855-2.438zM15.75 9a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
        <h3
          className={`text-white origin-left font-medium text-xl duration-200 ml-16 ${
            !open && "scale-0 ml-0"
          }`}
        >
          {open ? (userData ? userData.name : " ") : " "}
        </h3>
        <ul className={` mt-2 ml-16 ${!open && " m-0"}`}>
          <li
            className={`flex  rounded-md p-2 cursor-pointer hover:bg-light-white text-gray-300 text-sm items-center  `}
          >
            <Link to="/">
              <AiFillHome className="text-2xl text-white" />
            </Link>
            <span className={`${!open && "hidden"} origin-left duration-200`}>
              <Link to="/" className="ml-4">
                Home
              </Link>
            </span>
          </li>
          <li
            className={`flex  rounded-md p-2 cursor-pointer hover:bg-light-white text-gray-300 text-sm items-center gap-x-4 ${"mt-2"} `}
            onClick={() => handleMenuItemClick("CustomerOrder")}
          >
            <FaShoppingBag className="text-2xl" />

            <span className={`${!open && "hidden"} origin-left duration-200`}>
              My Order
            </span>
          </li>
          <li
            className={`flex  rounded-md p-2 cursor-pointer hover:bg-light-white text-gray-300 text-sm items-center gap-x-4 ${"mt-2"} `}
            onClick={() => handleMenuItemClick("CustomerProfile")}
          >
            <BsFillPersonFill className="text-2xl" />

            <span className={`${!open && "hidden"} origin-left duration-200`}>
              My Profile
            </span>
          </li>

          <li
            className={`flex  rounded-md p-2 cursor-pointer hover:bg-light-white text-gray-300 text-sm items-center gap-x-4 ${"mt-2"} `}
            onClick={() => handleMenuItemClick("CustomerCart")}
          >
            <FaShoppingCart className="text-2xl" />
            <span className={`${!open && "hidden"} origin-left duration-200`}>
              My Cart
            </span>
          </li>
          <li
            className={`flex  rounded-md p-2 cursor-pointer hover:bg-light-white text-gray-300 text-sm items-center gap-x-4 ${"mt-2"} `}
            onClick={() => handleMenuItemClick("tiffinDetails")}
          >
            <BsFillPersonFill className="text-2xl" />

            <span className={`${!open && "hidden"} origin-left duration-200`}>
              Tiffin Details
            </span>
          </li>

          <li
            className={`flex  rounded-md p-2 cursor-pointer hover:bg-light-white text-gray-300 text-sm items-center gap-x-4 ${"mt-2"} `}
            onClick={() => handleLogout()}
          >
            <RiLogoutCircleRFill className="text-2xl" />
            <span className={`${!open && "hidden"} origin-left duration-200`}>
              Logout
            </span>
          </li>
        </ul>
      </div>
      <div
        className="h-screen flex-1 w-96"
        style={{ marginLeft: open ? "20em" : "5em" }}
      >
        <div
          className={`min-h-screen bg-gray-100 ${
            selectedContent === "dashboard"
              ? "md:max-w-screen-[1415px]   "
              : "  "
          }`}
        >
          <main className="flex-1 p-3">
            {selectedContent === "dashboard" && (
              <div className="">
                <Link to="/">
                  <button
                    style={{
                      backgroundColor: "blue",
                      color: "white",
                      borderRadius: "10px",
                      display: "flex", // Use flex display
                      alignItems: "center", // Align items vertically in the center
                      width: "150px",
                    }}
                  >
                    <IoArrowBackSharp
                      style={{ marginLeft: "30px", height: "50px" }}
                    />{" "}
                    Back {/* Add margin to the right of the icon */}
                  </button>
                </Link>
                <img style={{ height: "30rem" }} src={dashbg} alt="hello" />
              </div>
            )}

            {selectedContent === "CustomerCart" && <CustomerCart />}
            {selectedContent === "CustomerProfile" && <CustomerProfile />}
            {selectedContent === "CustomerOrder" && <CustomerOrder />}
            {selectedContent === "tiffinDetails" && <TiffinDetails />}
          </main>
        </div>
      </div>
    </div>
  );
};

export default CustomerDash;
