import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import axios from "axios";
const TCProfile = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [_id, setId] = useState("");

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("user"));

    if (userData) {
      const { email, name, phone, address, _id } = userData;
      setName(name || "");

      setPhone(phone || "");
      setEmail(email || "");
      setAddress(address || "");
      setId(_id);
    }
  }, []);
  //// here start part of updated code
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log(_id, "this is id inside the handlesubmit ");
      const { data } = await axios.put("/api/v1/auth/update-profile", {
        _id,
        name,
        email,
        password,
        phone,
        address,
      });
      if (data?.error) {
        toast.error(data?.error);
      } else {
        // Update user data in local storage
        const userData = JSON.parse(localStorage.getItem("user"));
        if (userData) {
          userData.name = name;
          userData.phone = phone;
          userData.email = email;
          userData.address = address;
          localStorage.setItem("user", JSON.stringify(userData));
        }

        toast.success("Profile Updated Successfully");
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      toast.error("Something went wrong");
    }
  };

  // the end of that part of code

  return (
    <div className="container flex justify-center items-center h-screen my-12 w-2xl bg-gray-100">
      <form
        onSubmit={handleSubmit}
        className="bg-white w-full sm:w-2/3 md:w-1/2 lg:w-1/3 rounded-2xl shadow-xl rounded pt-6 pb-8 mb-4 space-y-4 px-4"
      >
        <h4 className="text-xl text-center text-purple-500 font-semibold mb-4">
          Tiffin Center Profile
        </h4>
        <div className="mb-4">
          <label
            className="block text-purple-500 text-sm font-bold mb-2"
            htmlFor="name"
          >
            Name
          </label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="shadow appearance-none border rounded-full w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="name"
            placeholder="Enter Your Name"
            autoFocus
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-purple-500 text-sm font-bold mb-2"
            htmlFor="email"
          >
            Email
          </label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="shadow appearance-none border rounded-full w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="email"
            placeholder="Enter Your Email"
            disabled
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-purple-500 text-sm font-bold mb-2"
            htmlFor="password"
          >
            Password
          </label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="shadow appearance-none border rounded-full w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="password"
            placeholder="Enter Your Password"
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-purple-500 text-sm font-bold mb-2"
            htmlFor="phone"
          >
            Phone
          </label>
          <input
            type="text"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            className="shadow appearance-none border rounded-full w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="phone"
            placeholder="Enter Your Phone"
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-purple-500 text-sm font-bold mb-2"
            htmlFor="address"
          >
            Address
          </label>
          <input
            type="text"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            className="shadow appearance-none border rounded-full w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="address"
            placeholder="Enter Your Address"
          />
        </div>
        <div className="text-center">
          <button
            type="submit"
            className="font-semibold bg-purple-500 via-pink-700 to-indigo-900 text-gray-100 rounded-full ring-2 ring-blue-200 px-6 py-2 hover:bg-white hover:text-gray-400 hover:ring-slate-300"
          >
            UPDATE
          </button>
        </div>
      </form>
    </div>
  );
};

export default TCProfile;
