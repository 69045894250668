import React, { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const CreateItem = () => {
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [price1, setPrice1] = useState("");
  const [price2, setPrice2] = useState("");
  const [description, setDescription] = useState("");
  const [photo, setPhoto] = useState("");
  const [tiffinId, setTiffinId] = useState("");
  const [category, setCategory] = useState("");
  const [thaliType, setThaliType] = useState("");

  const handleCreate = async (e) => {
    e.preventDefault();
    try {
      const itemData = new FormData();
      itemData.append("name", name);
      itemData.append("price1", price1);
      itemData.append("price2", price2);
      itemData.append("description", description);
      itemData.append("tiffinId", tiffinId);
      itemData.append("category", category);
      itemData.append("thaliType", thaliType);
      itemData.append("photo", photo);
      console.log(itemData, "iteeem");
      const { data } = await axios.post("/api/v1/item/create-item", itemData);
      if (!data.success) {
        toast.error(data.message);
      }

      if (data.success) {
        setName("");
        setPrice1("");
        setPrice2("");
        setDescription("");
        setPhoto("");
        setTiffinId("");
        setCategory("");
        setThaliType("");

        toast.success(data.message);

        navigate("/admindashboard");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="container mt-4">
      <div className="row dashboard ">
        <div className="col-md-10 ">
          <h1 className="text-2xl font-semibold mb-4 text-center underline  text-purple-500">
            Create Items
          </h1>
          <div className={`w-full md:w-1/2 lg:w-1/2 mx-auto text-purple-500`}>
            <div class="sm:col-span-3 mb-4">
              <label
                for="category"
                class="block text-sm font-medium leading-6 text-purple-500"
              >
                Select Category
              </label>
              <div class="mt-2 text-purple-500">
                <select
                  id="category"
                  name="category"
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                  class="block w-full px-4 py-2 border rounded focus:outline-none focus:ring focus:border-blue-300"
                >
                  <option disabled value="" selected>
                    Select category
                  </option>
                  <option value="veg">Veg</option>
                  <option value="nonveg">Non Veg</option>
                </select>
              </div>
            </div>

            <div class="sm:col-span-3 mb-4 text-purple-500">
              <label
                for="thaliType"
                class="block text-sm font-medium leading-6 text-purple-500"
              >
                Thali type
              </label>
              <div class="mt-2">
                <select
                  id="thaliType"
                  name="thaliType"
                  value={thaliType}
                  onChange={(e) => setThaliType(e.target.value)}
                  class="block w-full px-4 py-2 border rounded focus:outline-none focus:ring focus:border-blue-300"
                >
                  <option disabled selected value="">
                    Select thali
                  </option>
                  <option value="Basic Thali">Basic Thali</option>
                  <option value="Premium Thali">Premium Thali</option>
                  <option value="Special Thali">Special Thali</option>
                  <option value="Deluxe Thali">Deluxe Thali</option>
                </select>
              </div>
            </div>

            <div className="mb-3">
              <label className="block w-full p-4 border-dashed border-2  border-gray-300 cursor-pointer text-center">
                {photo ? photo.name : "Upload Photo"}
                <input
                  type="file"
                  name="photo"
                  accept="image/*"
                  // onChange={(e) => setPhoto(e.target.files[0])}
                  // hidden
                  onChange={(e) => {
                    setPhoto(e.target.files[0]);
                    e.target.value = null;
                  }}
                  hidden
                />
              </label>
            </div>

            <div className="mb-3 text-center">
              {photo && (
                <img
                  src={URL.createObjectURL(photo)}
                  alt="Item_Photo"
                  className="block mx-auto max-h-40"
                />
              )}
            </div>

            <div className="mb-3 text-center">
              <input
                type="text"
                value={name}
                placeholder="Name"
                className="block w-full px-4 py-2 border rounded focus:outline-none focus:ring focus:border-blue-300"
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="mb-3 text-center">
              <input
                type="text"
                value={tiffinId}
                placeholder="tiffinId"
                className="block w-full px-4 py-2 border rounded focus:outline-none focus:ring focus:border-blue-300"
                onChange={(e) => setTiffinId(e.target.value)}
              />
            </div>
            <div className="mb-3 text-center">
              <input
                type="field"
                value={description}
                placeholder="Description"
                className="block w-full px-4 py-2 border rounded focus:outline-none focus:ring focus:border-blue-300"
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
            {/* one time price  */}
            <div className="mb-3 text-center">
              <input
                type="number"
                value={price1}
                placeholder="Price for one time"
                className="block w-full px-4 py-2 border rounded focus:outline-none focus:ring  focus:border-blue-300"
                onChange={(e) => setPrice1(e.target.value)}
              />
            </div>

            {/* two time price  */}
            <div className="mb-3 text-center">
              <input
                type="number"
                value={price2}
                placeholder="Price for two time"
                className="block w-full px-4 py-2 border rounded focus:outline-none focus:ring  focus:border-blue-300"
                onChange={(e) => setPrice2(e.target.value)}
              />
            </div>

            <div className="mb-3 text-center">
              <button
                className="bg-gradient-to-r from-violet-300 to-violet-500 hover:bg-blue-600 text-white px-4 py-2 rounded"
                onClick={handleCreate}
              >
                CREATE ITEM
              </button>
            </div>
          </div>
        </div>
      </div>
      <Toaster />
    </div>
  );
};

export default CreateItem;
