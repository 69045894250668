import React, { useState, useEffect } from "react";
import axios from "axios";
import Invoice from "./Invoice";

const CustomerOrder = () => {
  const token = JSON.parse(localStorage.getItem("auth"));
  const userData = JSON.parse(localStorage.getItem("user"));
  const _id = userData._id;
  const [orderData, setOrderData] = useState([]);
  const [itemDetails, setItemDetails] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isInvoiceModalOpen, setIsInvoiceModalOpen] = useState(false);

  const handleClick = (subscription) => {
    console.log(itemDetails, "itemmm");
    setSelectedOrder(subscription); // Set the selected order
    setIsInvoiceModalOpen(true);
  };

  const getOrders = async () => {
    try {
      const response = await axios.get(`/api/v1/payment/single-order/${_id}`);
      console.log(response.data.order.length, "this is response");
      console.log(response.data, "this is data");

      const { success, message, order } = response.data;

      if (success && message === "find") {
        setOrderData(order);

        const itemDetailsPromises = order.map((orderItem) =>
          orderItem.item.map((item) =>
            axios.get(`/api/v1/item/get-singleItemById/${item.item}`)
          )
        );
        const itemDetailsResponses = await Promise.all(
          itemDetailsPromises.flat()
        );
        const itemDetailsData = itemDetailsResponses.map(
          (itemResponse) => itemResponse.data.item
        );
        setItemDetails(itemDetailsData);
      } else {
        console.error("Error fetching orders:", message);
      }
    } catch (error) {
      console.error("Error fetching orders:", error.message);
    }
  };

  useEffect(() => {
    // Fetch orders only if token is present
    if (token) {
      getOrders();
      console.log(itemDetails, "item details");
    }
  }, [token, _id]);

  return (
    <div className="container mx-auto">
      <h1 className="text-center font-bold mb-4">Orders</h1>
      {orderData.length === 0 && (
        <div className="mt-4">
          <h1 className="text-red-500 text-center">
            You don't have any orders
          </h1>
        </div>
      )}

      {orderData.length > 0 && (
        <div className="overflow-x-auto">
          <table className="w-full bg-white border border-gray-300">
            <thead>
              <tr>
                <th className="border border-gray-300">Order ID</th>
                <th className="border border-gray-300">Status</th>
                <th className="border border-gray-300">Date</th>
                <th className="border border-gray-300">Payment</th>
                <th className="border border-gray-300">Payment Mode</th>
              </tr>
            </thead>
            <tbody>
              {orderData.map((order, orderIndex) => (
                <tr key={orderIndex}>
                  <td className="border border-gray-300">
                    {order?.razorpay?.orderId}
                  </td>
                  <td className="border border-gray-300">{order?.status}</td>
                  <td className="border border-gray-300">
                    {new Date(order?.createdAt).toLocaleDateString("en-US", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    })}
                  </td>
                  <td className="border border-gray-300">
                    {order?.isPaid ? "Success" : "Failed"}
                  </td>
                  <td className="border border-gray-300">
                    {order?.paymentMode ? "Online" : "Cash"}
                  </td>
                  <td className="px-2 py-2 whitespace-nowrap">
                    <button
                      className="ml-2 text-blue-500 cursor-pointer"
                      onClick={() => handleClick(order)}
                    >
                      👁️
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {itemDetails.length > 0 && (
            <div className="mt-4">
              <h2 className="text-lg font-semibold">Order Items</h2>
              <div className="flex flex-wrap h-mx-16">
                {itemDetails.map((item, itemIndex) => (
                  <div
                    key={itemIndex}
                    className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 p-0.5"
                  >
                    <div className="border p-4 rounded-md shadow-md">
                      <h3 className="text-lg font-semibold">
                        Name: {item?item.name:""}
                      </h3>


                      <img
                        src={`/api/v1/item/get-photo/${item._id}`}
                        alt={item.name}
                        className="mt-2 rounded-md"
                        width="100%"
                      />
                      <h3 className="text-lg font-semibold">
                        Description: {item.description.slice(0,14)}
                      </h3>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}

      <Invoice
        isOpen={isInvoiceModalOpen}
        onClose={() => setIsInvoiceModalOpen(false)}
        orderData={selectedOrder}
      />
    </div>
  );
};

export default CustomerOrder;
