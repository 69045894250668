import React, { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import moment from "moment/moment";
import { RiEditFill } from "react-icons/ri";
import { MdDeleteSweep } from "react-icons/md";
import { LuEye } from "react-icons/lu";
import ViewTCMOdal from "./ViewTcMo";
import EditTcModel from "./EditTcModel";

const ViewAllTc = () => {
  const isMobileView = window.innerWidth <= 420;
  const [tc, setTc] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [viewData, setViewData] = useState(null);
  const [editedData, setEditedData] = useState(null);
  const [searchId, setSearchId] = useState("");
  const handleUpdate = async (formData) => {
    const { _id } = formData;
    try {
      const response = await axios.put(
        `/api/v1/auth/update-tc/${_id}`,
        formData
      );

      console.log("Response from server:", response);

      if (response.status === 201) {
        toast.success("Tifffin Center Updated Successfully");
        console.log("tc updated successfully");
        setEditedData(response.data.updatedUser);
        setShowEditModal(true);
        getAllTiffinCenter();
      } else {
        console.error("Failed to update tc");
      }
    } catch (error) {
      console.error("Error updating tc:", error);
    }
  };

  const handleViewData = async (id) => {
    try {
      const response = await axios.get(`/api/v1/auth/get-tc/${id}`);

      if (response.status === 200) {
        // console.log("Tc View successfully");
        // Assuming response.data contains the individual item data
        setViewData(response.data);
        setShowModal(true);
      } else {
        console.error("Failed to View Tc");
      }
    } catch (error) {
      console.error("Error in view Tc:", error);
    }
  };
  const handleDelete = async (id) => {
    try {
      await axios.delete(`/api/v1/auth/delete-tc/${id}`);
      const updatedTCData = tc.filter((item) => item._id !== id);
      setTc(updatedTCData);
    } catch (error) {
      console.error("Error deleting TC data:", error);
    }
  };

  // get all customer
  const getAllTiffinCenter = async (id = "") => {
    try {
      const url = id
        ? `/api/v1/auth/getS-search/${id}`
        : "/api/v1/auth/get-alltcenter";

      const res = await axios.get(url);
      const { data } = res;
      if (data) {
        if (data.length > 0) setTc(data);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something Went Wrong");
    }
  };
  const handleSearch = () => {
    getAllTiffinCenter(searchId);
  };
  const handleEnterKey = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  // lifecycle method
  useEffect(() => {
    getAllTiffinCenter();
  }, []);

  return (
    <div className="container mx-auto ">
      <h1 className="text-3xl font-semibold text-center mb-6 text-purple-500 underline">
        All Tiffin Center
      </h1>
      <div className="mb-4 flex justify-end">
        <input
          type="text"
          placeholder="search by id name invoice"
          className="p-2 mr-2 border"
          value={searchId}
          onChange={(e) => setSearchId(e.target.value)}
          onKeyUp={handleEnterKey}
        />
        <button
          className="bg-purple-500 text-white px-4 py-2 rounded"
          onClick={handleSearch}
        >
          Search
        </button>
      </div>
      <div className="overflow-x-auto">
        <table className="w-full border-collapse bg-white shadow-lg rounded-lg">
          <thead>
            <tr className="bg-purple-500 text-white">
              <th className="py-3 px-4 text-left">Tiffiin Center Name</th>
              <th className="py-3 px-4 text-left">Phone</th>
              <th className="py-3 px-4 text-left">Address</th>
              <th className="py-3 px-4 text-left">Serving Area</th>
              <th className="py-3 px-4  text-left ">Status</th>
              <th className="py-3 px-4  text-left ">Subscription Plan</th>
              <th className="py-3 px-4">Subscription Expiry Date</th>
              <th className="py-3 px-4">Actions</th>
            </tr>
          </thead>
          <tbody>
            {tc?.map((p, index) => (
              <tr
                style={{ border: "1px solid black" }}
                key={index}
                className={`${
                  index % 2 === 0 ? "bg-purple-300" : "bg-purple-300"
                } bg-purple-300`}
              >
                <td className="py-3 px-4 whitespace-nowrap">{p.tiffinName}</td>
                <td className="py-3 px-4">{p.phone}</td>
                <td className="py-2 px-4">{p.address}</td>
                <td className="py-3 px-4">
                  {p.pincode}, {p.pincode1}, {p.pincode2}, {p.pincode3},{" "}
                  {p.pincode4}
                </td>
                <td className="py-2 px-4">{p.status}</td>
                <td className="py-2 px-4">{p.subscriptionPlan}</td>
                <td
                  className={`px-4 py-2 mt-5 ${
                    !isMobileView ? "flex flex-row items-center" : ""
                  }`}
                >
                  {moment(p.endDate).format("DD-MM-YYYY")}
                </td>

                <td className="px-3">
                  <button
                    className="hover:text-yellow-500 mr-2 hover:text-2xl"
                    onClick={() => handleViewData(p._id)}
                  >
                    <LuEye />
                  </button>
                  <button
                    className="hover:text-yellow-500  mr-2 hover:text-2xl"
                    onClick={() => handleUpdate(p)}
                  >
                    <RiEditFill />
                  </button>
                  <button
                    className="hover:text-yellow-500 mr-2 hover:text-2xl"
                    onClick={() => handleDelete(p._id)}
                  >
                    <MdDeleteSweep />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {showModal && (
        <ViewTCMOdal
          onView={handleViewData}
          initialData={viewData}
          setOpenModal={setShowModal}
        />
      )}
      {showEditModal && (
        <EditTcModel
          onUpdate={handleUpdate}
          initialData={editedData}
          setOpenModal={setShowEditModal}
        />
      )}
    </div>
  );
};

export default ViewAllTc;
