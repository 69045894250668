import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from 'react-modal';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { IoMdClose } from "react-icons/io";
Modal.setAppElement('#root');

const ViewTcSubcription = () => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchId, setSearchId] = useState("");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async (id = "") => {
    try {
      const url = id
        ? `/api/v1/payment/getS-subs/${id}`
        : "/api/v1/payment/getall-subs";

      const response = await axios.get(url);
      console.log(response, "thisis ");

      


      setSubscriptions(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const openModal = (subscription) => {
    setSelectedSubscription(subscription);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handlePrint = () => {
    alert("Printing the invoice...");
  };

  const handleSearch = () => {
    fetchData(searchId);
  };
  const handleEnterKey = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const generatePdf = (selectedSubscription, event) => {
   
    event.preventDefault(); 

    console.log("Generating PDF...");
    try {
      const doc = new jsPDF();
      // Header
      doc.setFontSize(20);
      doc.text('Receipt', 105, 20, { align: 'center' });

      // Company Name
      doc.setFontSize(16);
      doc.text('Manvika Consultancy Services Private Limited', 105, 40, { align: 'center' });
      doc.text('“You Choose, We Fulfill”', 105, 60, { align: 'center' });

  
      // Content
      const marginLeft = 60;
      const dataNameX = marginLeft;
      const valueX = 140;

      doc.setFontSize(12);

      // Date
      doc.text('Date:', dataNameX, 80, { align: 'center' });
      doc.text(new Date(selectedSubscription.createdAt).toLocaleDateString(), valueX, 80, { align: 'center' });

      // Receipt No
      doc.text('Receipt No:', dataNameX, 90, { align: 'center' });
      doc.text(selectedSubscription.invoiceNo.toString(), valueX, 90, { align: 'center' });

      // Received From
      doc.text('Received From:', dataNameX, 100, { align: 'center' });
      doc.text(selectedSubscription.tcOwnerName.toString(), valueX, 100, { align: 'center' });

      // Rs
      doc.text('Rs:', dataNameX, 110, { align: 'center' });
      doc.text(selectedSubscription.amount.toString(), valueX, 110, { align: 'center' });

      // For
      doc.text('For:', dataNameX, 120, { align: 'center' });
      doc.text(selectedSubscription.subscriptionPlan.toString(), valueX, 120, { align: 'center' });


      doc.text("Signature", dataNameX, 140, { align: 'left' });



      // Download Invoice Button
      /* doc.setDrawColor(52, 152, 219);
      doc.setFillColor(52, 152, 219);
      doc.roundedRect(105, 135, 80, 20, 3, 3, 'FD'); // Blue button
      doc.setTextColor(255, 255, 255);
      doc.text('Download Invoice', 145, 147, { align: 'center' }); */

      // Save the PDF
      doc.save('Receipt.pdf');
      console.log("PDF generated successfully");
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  

  
  };

  return (
    <div className="md:overflow-x-auto sm:overflow-x-auto lg:overflow-x-auto">
      <h1 className="text-2xl font-bold mb-4 text-purple-900 text-center underline">
        Subscription List
      </h1>
      <div className="mb-4 flex justify-end">
        <input
          type="text"
          placeholder="Enter ID"
          className="p-2 mr-2 border border-purple-900"
          value={searchId}
          onChange={(e) => setSearchId(e.target.value)}
          onKeyUp={handleEnterKey}
        />
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded"
          onClick={handleSearch}
        >
          Search
        </button>
      </div>
      <div className="w-full overflow-x-auto">
        <table className="min-w-full table-auto ">
          <thead className="bg-purple-500">
            <tr className="bg-purple-900 border-2	 to-slate-900 border-md border-white">
              <th className="px-5 py-2 text-center   w-10  text-xs font-medium text-white uppercase tracking-wider">
                Tiffin_ID
              </th>
              <th className="px-2 py-2  text-center w-auto text-xs font-medium text-white uppercase tracking-wider">
                Name
              </th>
              <th className="px-2 py-2 text-center  w-auto text-xs font-medium text-white uppercase tracking-wider">
                Amount
              </th>
              <th className="px-2 py-2 text-center w-auto  text-xs font-medium text-white uppercase tracking-wider">
                Plan
              </th>
              <th className="px-2 py-2 text-center w-auto text-xs font-medium text-white uppercase tracking-wider">
                Owner Name
              </th>
              <th className="px-2 py-2 text-center w-auto text-xs font-medium text-white uppercase tracking-wider">
                Invoice_No
              </th>
              <th className="px-2 py-2 text-center w-auto text-xs font-medium text-white uppercase tracking-wider">
                Date
              </th>
              <th className="px-2 py-2 text-center w-auto text-xs font-medium text-white uppercase tracking-wider">
                Order ID
              </th>
              <th className="px-2 py-2 text-center w-auto text-xs font-medium text-white uppercase tracking-wider">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(subscriptions) &&
              subscriptions.map((subscription) => (
                <tr key={subscription._id} className="border-2	bg-purple-300">
                  <td className="px-2 text-center py-2 whitespace-nowrap">
                    {subscription.tiffinId}
                  </td>
                  <td className="px-2 py-2  text-center py-2 whitespace-nowrap">
                    {subscription.tiffinName}
                  </td>
                  <td className="px-2  text-center py-2  whitespace-nowrap">
                    {subscription.amount}
                  </td>
                  <td className="px-2  text-center py-2 py-2 whitespace-nowrap">
                    {subscription.subscriptionPlan}
                  </td>
                  <td className="px-2 py-2 whitespace-nowrap">
                    {subscription.tcOwnerName}
                  </td>
                  <td className="px-2  text-center py-2 whitespace-nowrap">
                    {subscription.invoiceNo}
                  </td>

                  <td className="px-2  text-center py-2 whitespace-nowrap">
                    {new Date(subscription.createdAt).toLocaleDateString()}
                  </td>
                  <td className="px-2  text-center py-2 whitespace-nowrap">
                    {subscription.razorpay?.orderId || "N/A"}
                  </td>

                  <td className="px-2  text-center py-2 py-2 whitespace-nowrap">
                    <button
                      className="ml-2 float-right  right-0 text-blue-500 cursor-pointer"
                      onClick={() => openModal(subscription)}
                    >
                      👁️
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      {isModalOpen && (
        
          <Modal
            isOpen={openModal}
            onRequestClose={closeModal}
            contentLabel="Receipt Modal"
            style={{
              content: {
                width: "70%",
                maxWidth: "1100px",
                maxHeight: "90vh",
                margin: "auto",
                padding: "20px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                borderRadius: "8px",
                overflowY: "auto",
                position: "relative",
                top: "50%",
                transform: "translateY(-50%)",
                border: "2px solid #8A2BE2",
              },
            }}
          >
            <div className="flex  justify-end">
              <button className="text-gray-500 hover:text-gray-700" onClick={closeModal}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            {selectedSubscription && (
              <div className="flex justify-center items-center">
                <form className="w-full max-w-6xl border border-gray-300 p-6 rounded-md">
                  <h1 className="underline text-violet-800 text-center p-0 m-0 text-3xl my-0">
                    Receipt
                  </h1>

                  <h1 className="text-violet-800 text-center p-2 m-2 text-2xl">
                    Manvika Consultancy Services Private Limited
                  </h1>
                  <h1 className="text-violet-800 text-center p-2 m-2 text-2xl">
                    “You Choose, We Fulfill”
                  </h1>
                  <br />

                  <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                      <label
                        htmlFor="invoice-date"
                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      >
                        Date:
                      </label>
                      <input
                        className="appearance-none block w-full bg-white-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                        id="invoice-date"
                        value={new Date(selectedSubscription.createdAt).toLocaleDateString()}
                        type="text"
                        placeholder="Enter date"
                      />
                      
                    </div>
                    <div className="w-full md:w-1/2 px-3">
                      <label
                        htmlFor="invoice-number"
                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      >
                        Receipt No:
                      </label>
                      <input
                        className="appearance-none block w-full bg-white-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="invoice-number"
                        value={selectedSubscription.invoiceNo }
                        type="text"
                        placeholder=""
                      />
                      
                    </div>
                  </div>
                  <div className="flex flex-wrap -mx-3 mb-3">
                    <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                      <label
                        htmlFor="bill-to"
                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      >
                        Received From:
                      </label>
                      <input
                        className="appearance-none block w-full bg-white-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="bill-to"
                        value={selectedSubscription.tcOwnerName}
                        type="text"
                        placeholder=""
                      />

                      
                    </div>
                    <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                      <label
                        htmlFor="address"
                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      >
                        Rs:
                      </label>
                      <input
                        className="appearance-none block w-full bg-white-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="address"
                        value={selectedSubscription.amount}
                        type="text"
                        placeholder=""
                      />
                      
                    </div>
                    <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                      <label
                        htmlFor="contact"
                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      >
                        for:
                      </label>
                      <input
                        className="appearance-none block w-full bg-white-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="contact"
                        value={selectedSubscription.subscriptionPlan}
                        type="text"
                        placeholder=""
                      />

                     

                    </div>
                </div>
                <div className="text-center mt-8">
                  <button
                    className="bg-blue-500 hover:bg-blue-700 text-white mt-4 font-bold py-2 px-4 rounded"
                    onClick={(event) => generatePdf(selectedSubscription, event)}
                  >
                    Download Invoice
                  </button>
                </div>
                  <div className="flex justify-end items-center">
                    <div className="text-right">
                      <p className="w-32 md:w-32 lg:w-48 text-xl font-medium text-gray-700">
                        Signature
                      </p>
                  </div>
                  
                  </div>
                </form>
              </div>
            )}
          </Modal>
        
      )}
    </div>
  );
};

export default ViewTcSubcription;
