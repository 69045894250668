import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from "react-modal";

const AttendModal = ({ isOpen, onClose, orderId }) => {
  const [attendanceData, setAttendanceData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/attendence/attendance/${orderId}`);
        console.log(response.data);

        if (response.status === 200) {
          const data = Array.isArray(response.data)
            ? response.data
            : [response.data];
          setAttendanceData(data);
        } else {
          console.error(
            "Failed to retrieve attendance data. API response:",
            response.data
          );
        }
      } catch (error) {
        console.error("Error fetching attendance data", error);
      }
    };

    if (isOpen && !attendanceData.length) {
      fetchData();
    }

    // Cleanup function to reset data when modal is closed
    return () => {
      setAttendanceData([]);
    };
  }, [isOpen, orderId]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Edit Modal"
      style={{
        content: {
          width: "60%",
          maxWidth: "800px",
          margin: "auto",
          padding: "20px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          borderRadius: "8px",
          overflow: "hidden",
          position: "relative",
          top: "50%",
          transform: "translateY(-50%)",
          border: "2px solid #8A2BE2", // Purple border color
        },
      }}
    >
      <div className="flex justify-end">
        <button className="text-gray-500 hover:text-gray-700" onClick={onClose}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>

      <div className="flex flex-col items-center mt-4">
        <h1 className="text-3xl font-bold mb-4">Tiffin Status</h1>
        <table className="w-full mt-4 border border-purple-500">
          <thead>
            <tr>
              <th className="border border-purple-500 p-2">Date</th>
              <th className="border border-purple-500 p-2">Morning Status</th>
              <th className="border border-purple-500 p-2">Evening Status</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(attendanceData) && attendanceData.length > 0 ? (
              attendanceData.map((attendance, index) => (
                <tr key={index}>
                  <td className="border border-purple-500 p-2">
                    {attendance.date}
                  </td>
                  <td className="border border-purple-500 p-2">
                    {attendance.morningAttendance}
                  </td>
                  <td className="border border-purple-500 p-2">
                    {attendance.eveningAttendance}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td className="border border-purple-500 p-2" colSpan="3">
                  No Status available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </Modal>
  );
};

export default AttendModal;
