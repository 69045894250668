import React, { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import moment from "moment/moment";
import { RiEditFill } from "react-icons/ri";
import { MdDeleteSweep } from "react-icons/md";
import { LuEye } from "react-icons/lu";

const ViewAllDel = () => {
  const [staff, setStaff] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [selectedDk, setSelectedDk] = useState(null);
  const isMobileView = window.innerWidth <= 420;
  const [searchId, setSearchId] = useState("");

  const EditModal = ({ selectedStaff, closeEditModal }) => {
    const [formData, setFormData] = useState({
      name: selectedStaff.name,

      status: selectedStaff.status,
      pincode: selectedStaff.pincode,
      address: selectedStaff.address,
      phone: selectedStaff.phone,
      dln: selectedStaff.dln,
      acn: selectedStaff.acn,
      uniqueId: selectedStaff.uniqueId,
      address: selectedStaff.address,
      pincode: selectedStaff.pincode,
      email: selectedStaff.email,
    });

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    };

    const handleStatusChange = (e) => {
      const status = e.target.value;
      setFormData({
        ...formData,
        status,
      });
    };

    const handleEdit = async () => {
      try {
        await axios.put(
          `/api/v1/auth/update-del/${selectedStaff._id}`,
          formData
        );

        toast.success("Delboy Updated successfully");
        closeEditModal();

        getAllCustomer();
      } catch (error) {
        console.error(error);
        toast.error("Error in Updating  staff");
      }
    };

    return (
      <div className="fixed inset-0 flex items-center md:w-1/0.5 p-4 mx-auto text-center justify-center  bg-opacity-75">
        <div className="bg-white p-8 rounded-lg max-w-full max-h-full overflow-auto">
          <h2 className="text-2xl font-semibold underline">
            Update Delivery Person
          </h2>
          <form>
            <div className="overflow-x-auto ">
              <table className="w-auto">
                <tbody>
                  <tr>
                    <th>
                      <label>Name:</label>
                    </th>
                    <th>
                      <label>Contact:</label>
                    </th>
                    <th>
                      <label>Driving Lc. No:</label>
                    </th>
                  </tr>
                  <tr>
                    <td className="">
                      <input
                        type="text"
                        name="name"
                        className=" text-center"
                        value={formData.name}
                        onChange={handleInputChange}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="phone"
                        className=" text-center"
                        value={formData.phone}
                        onChange={handleInputChange}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="dln"
                        className=" text-center"
                        value={formData.dln}
                        onChange={handleInputChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label>Aadhar:</label>
                    </th>
                    <th>
                      <label>uniqueId:</label>
                    </th>
                    <th>
                      <label>Status:</label>
                    </th>
                  </tr>
                  <tr>
                    <td>
                      <input
                        type="text"
                        name="acn"
                        className="w-auto text-center"
                        value={formData.acn}
                        onChange={handleInputChange}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="uniqueId"
                        className=" text-center"
                        value={formData.uniqueId}
                        onChange={handleInputChange}
                      />
                    </td>
                    <td>
                      <select
                        className=" text-center"
                        name="status"
                        value={formData.status}
                        onChange={handleStatusChange}
                      >
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label>Address:</label>
                    </th>
                    <th>
                      <label>Pin Code:</label>
                    </th>
                    <th>
                      <label>Email:</label>
                    </th>
                  </tr>
                  <tr>
                    <td>
                      <input
                        type="text"
                        name="acn"
                        className="w-auto text-center"
                        value={formData.address}
                        onChange={handleInputChange}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="uniqueId"
                        className=" text-center"
                        value={formData.pincode}
                        onChange={handleInputChange}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="uniqueId"
                        className=" text-center"
                        value={formData.email}
                        onChange={handleInputChange}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </form>
          <tr>
            <td colSpan="2" className="text-center">
              <button
                className="mt-4 bg-blue-500 text-white px-4 py-2 mx-2 rounded hover:bg-blue-600"
                onClick={closeEditModal}
              >
                Close
              </button>
              <button
                className="mt-4 bg-yellow-500 text-white px-4 py-2 mx-2 rounded hover:bg-blue-600"
                onClick={() => handleEdit(selectedStaff._id, formData)}
              >
                Update
              </button>
            </td>
          </tr>
        </div>
      </div>
    );
  };
  const ViewModal = ({ selectedDk, closeViewModal }) => {
    console.log(selectedDk, "all prop");
    const [formData, setFormData] = useState({
      name: selectedDk.name,

      status: selectedDk.status,
      pincode: selectedDk.pincode,
      address: selectedDk.address,
      phone: selectedDk.phone,
      dln: selectedDk.dln,
      acn: selectedDk.acn,
      uniqueId: selectedDk.uniqueId,
      address: selectedDk.address,
      pincode: selectedDk.pincode,
      email: selectedDk.email,
    });

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    };

    const handleStatusChange = (e) => {
      const status = e.target.value;
      setFormData({
        ...formData,
        status,
      });
    };

    const handleEdit = async () => {
      try {
        await axios.put(`/api/v1/auth/update-del/${selectedDk._id}`, formData);

        getAllCustomer();
      } catch (error) {
        console.error(error);
        toast.error("Error in Updating  staff");
      }
    };

    return (
      <div className="fixed inset-0 flex items-center md:w-1/0.5 p-4 mx-auto text-center justify-center  bg-opacity-75">
        <div className="bg-white p-8 rounded-lg max-w-full max-h-full overflow-auto">
          <h2 className="text-2xl font-semibold underline">View Delivery</h2>

          <form>
            <div className="overflow-x-auto ">
              <table className="w-auto">
                <tbody>
                  <tr>
                    <th>
                      <label>Name:</label>
                    </th>
                    <th>
                      <label>Contact:</label>
                    </th>
                    <th>
                      <label>Driving Lc. No:</label>
                    </th>
                  </tr>
                  <tr>
                    <td className="">
                      <input
                        type="text"
                        name="name"
                        className=" text-center"
                        value={formData.name}
                        onChange={handleInputChange}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="phone"
                        className=" text-center"
                        value={formData.phone}
                        onChange={handleInputChange}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="dln"
                        className=" text-center"
                        value={formData.dln}
                        onChange={handleInputChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label>Aadhar:</label>
                    </th>
                    <th>
                      <label>uniqueId:</label>
                    </th>
                    <th>
                      <label>Status:</label>
                    </th>
                  </tr>
                  <tr>
                    <td>
                      <input
                        type="text"
                        name="acn"
                        className="w-auto text-center"
                        value={formData.acn}
                        onChange={handleInputChange}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="uniqueId"
                        className=" text-center"
                        value={formData.uniqueId}
                        onChange={handleInputChange}
                      />
                    </td>
                    <td>
                      <select
                        className=" text-center"
                        name="status"
                        value={formData.status}
                        onChange={handleStatusChange}
                      >
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label>Address:</label>
                    </th>
                    <th>
                      <label>Pin Code:</label>
                    </th>
                    <th>
                      <label>Email:</label>
                    </th>
                  </tr>
                  <tr>
                    <td>
                      <input
                        type="text"
                        name="acn"
                        className="w-auto text-center"
                        value={formData.address}
                        onChange={handleInputChange}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="uniqueId"
                        className=" text-center"
                        value={formData.pincode}
                        onChange={handleInputChange}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="uniqueId"
                        className=" text-center"
                        value={formData.email}
                        onChange={handleInputChange}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </form>
          <tr>
            <td colSpan="2" className="text-center">
              <button
                className="mt-4 bg-blue-500 text-white px-4 py-2 mx-2  rounded hover:bg-blue-600"
                onClick={closeViewModal}
              >
                Close
              </button>
            </td>
          </tr>
        </div>
      </div>
    );
  };
  const getAllCustomer = async (id = "") => {
    try {
      const url = id
        ? `/api/v1/auth/get-searchDel/${id}`
        : "/api/v1/auth/get-delboy";
      const { data } = await axios.get(url);
      console.log(data, "dafafsdf");

      setStaff(data);
    } catch (error) {
      console.log(error);
      toast.error("Something Went Wrong");
    }
  };
  const handleSearch = () => {
    getAllCustomer(searchId);
  };
  const handleEnterKey = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  useEffect(() => {
    getAllCustomer();
  }, []);

  const openEditModal = (index) => {
    setSelectedStaff(staff[index]);
  };

  const closeEditModal = () => {
    setSelectedStaff(null);
  };

  // start here for view
  const openViewModal = (index) => {
    setSelectedDk(staff[index]);
  };

  const closeViewModal = () => {
    setSelectedDk(null);
  };

  //end her for view

  const handleDelete = async (_id) => {
    try {
      await axios.delete(`/api/v1/auth/delete-del/${_id}`);
      toast.success("DelBoy Deleted Successfully....!");
      closeEditModal();
      getAllCustomer();
    } catch (error) {}
  };

  return (
    <div className="container mx-auto mt-8">
      <h1 className="text-3xl font-semibold text-center mt-1 mb-6 text-purple-500">
        All Delivery Boy
      </h1>
      <div className="mb-4 flex justify-end">
        <input
          type="text"
          placeholder="search by id name invoice"
          className="p-2 mr-2 border"
          value={searchId}
          onChange={(e) => setSearchId(e.target.value)}
          onKeyUp={handleEnterKey}
        />
        <button
          className="bg-purple-500 text-white px-4 py-2 rounded"
          onClick={handleSearch}
        >
          Search
        </button>
      </div>
      <div className="overflow-x-auto">
        <table className="w-full border-collapse shadow-lg rounded-lg">
          <thead>
            <tr className="text-white bg-purple-500">
              <th className="py-3 px-4 text-left">Sn</th>
              <th className="py-3 px-4 text-left">Name</th>
              <th className="py-3 px-4 text-left">UniqueId</th>
              <th className="py-3 px-4 text-left">Contact</th>
              <th className="py-3 px-4 text-left">Address</th>
              <th className="py-3 px-4 text-left">Date</th>
              <th className="py-3 text-left">Actions</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(staff) &&
              staff?.map((p, index) => (
                <tr
                  style={{ border: "1px solid black" }}
                  key={index}
                  className={`${
                    index % 2 === 0 ? "bg-purple-300 " : "bg-purple-300"
                  } bg-purple-300`}
                >
                  <td className="py-3 px-4 whitespace-nowrap">{index + 1}</td>
                  <td className="py-1">{p.name}</td>
                  <td className="py-1">{p.uniqueId}</td>
                  <td className="py-1">{p.phone}</td>
                  <td className="py-1">{p.address}</td>
                  <td
                    className={`px-0 py-2 ${
                      !isMobileView ? "flex flex-row items-center" : ""
                    }`}
                  >
                    {moment(p.createdAt).format("DD-MM-YYYY")}
                  </td>
                  <td className="px-3">
                    <button
                      className="hover:text-yellow-500 mr-2 hover:text-2xl"
                      onClick={() => openViewModal(index)}
                    >
                      <LuEye />
                    </button>
                    <button
                      className="hover:text-yellow-500  mr-2 hover:text-2xl"
                      onClick={() => openEditModal(index)}
                    >
                      <RiEditFill />
                    </button>
                    <button
                      className="hover:text-yellow-500 mr-2 hover:text-2xl"
                      onClick={() => handleDelete(p._id)}
                    >
                      <MdDeleteSweep />
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      {selectedStaff && (
        <EditModal
          selectedStaff={selectedStaff}
          closeEditModal={closeEditModal}
        />
      )}

      {selectedDk && (
        <ViewModal selectedDk={selectedDk} closeViewModal={closeViewModal} />
      )}
    </div>
  );
};

export default ViewAllDel;
