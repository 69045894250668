import React, { useState, useEffect, Fragment, useRef } from "react";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import { Dialog, Transition } from "@headlessui/react";
import { useParams } from "react-router-dom";
import { AiOutlineCamera } from "react-icons/ai";
import { FcEditImage } from "react-icons/fc";

const ViewItems = () => {
  const [item, setItem] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedItem, setSelectedItem] = useState(null);
  const [open, setOpen] = useState(false);
  const cancelButtonRef = useRef(null);

  const params = useParams();

  const [name, setName] = useState("");
  const [price1, setPrice1] = useState(0);
  const [price2, setPrice2] = useState(0);
  const [photo, setPhoto] = useState(null);
  const [id, setId] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [thaliType, setThaliType] = useState("");
  const [tiffinId, setTiffinId] = useState("");

  const getAllItem = async () => {
    try {
      const { data } = await axios.get("/api/v1/item/get-item");
      if (data?.success) {
        setItem(data.item);
      }
    } catch (error) {
      console.log(error);
      // toast.error("Something went wrong in getting food");
    } finally {
      setLoading(false);
    }
  };

  const getSingleItem = async (slug) => {
    try {
      const { data } = await axios.get(`/api/v1/item/get-singleItem/${slug}`);
      setName(data.item.name);
      setId(data.item._id);
      setPrice1(data.item.price1);
      setPrice2(data.item.price2);
      setDescription(data.item.description);
      setCategory(data.item.category);
      setThaliType(data.item.thaliType);
      setTiffinId(data.item.tiffinId);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllItem();
  }, []);

  const handleUpdateClick = (slug) => {
    setSelectedItem(slug);
    getSingleItem(slug);
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const itemData = new FormData();
      itemData.append("category", category);
      itemData.append("name", name);
      itemData.append("price1", price1);
      itemData.append("price2", price2);
      itemData.append("photo", photo);
      itemData.append("description", description);
      itemData.append("tiffinId", tiffinId);
      const { data } = await axios.put(
        `/api/v1/item/update-item/${id}`,
        itemData
      );
      if (data?.success) {
        toast.success("Item Updated Successfully");
        setOpen(false);
        getAllItem(); // Refresh the list of items after update
      } else {
        toast.error(data?.message);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong during update");
    }
  };

  const handleDelete = async () => {
    try {
      const { data } = await axios.delete(`/api/v1/item/delete-item/${id}`);
      if (data?.success) {
        toast.success("Item Deleted Successfully");
        setOpen(false);
        getAllItem(); // Refresh the list of items after deletion
      } else {
        toast.error(data?.message);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong during deletion");
    }
  };
  const handleDel = async (_id) => {
    try {
      const id = _id;
      const { data } = await axios.delete(`/api/v1/item/delete-item/${id}`);
      if (data?.success) {
        toast.success(data.message);
        console.log(data.message);
        setOpen(false);
        getAllItem();
      } else {
        toast.error(data?.message);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong during deletion");
    }
  };

  return (
    <>
      <h1 className="text-2xl text-center uppercase font-semibold mb-1 text-purple-500 underline">
        All Items
      </h1>
      <div className="container mx-auto py-1 overflow-x-auto">
        {loading ? (
          <p>Loading...</p>
        ) : (
          <table className="min-w-full">
            <thead>
              <tr className="text-white bg-purple-500">
                <th className="py-2 px-4 text-left">Name</th>
                <th className="py-2 px-4 text-left">Image</th>
                <th className="py-2 px-4 text-left">Price1</th>
                <th className="py-2 px-4 text-left">Price2</th>
                <th className="py-2 px-4 text-left">Description</th>
                <th className="py-2 px-4 text-left">Category</th>
                <th className="py-2 px-4 text-left">ThaliType</th>
                <th className="py-2 px-4 text-left">TiffinID</th>

                <th className="py-2 px-4 text-left">Actions</th>
              </tr>
            </thead>
            <tbody className="bg-purple-300 text-black border-black">
              {item.map((p) => (
                <tr key={p._id} className="border-b">
                  <td className="py-2 px-4">{p.name}</td>
                  <td className="py-2 px-4">
                    <img
                      src={`/api/v1/item/get-photo/${p._id}`}
                      className="w-20 h-auto"
                      alt={p.name}
                    />
                  </td>
                  <td className="py-2 px-4">{p.price1}</td>
                  <td className="py-2 px-4">{p.price2}</td>
                  <td className="py-2 px-4">{p.description}</td>
                  <td className="py-2 px-4">{p.category}</td>
                  <td className="py-2 px-4">{p.thaliType}</td>
                  <td className="py-2 px-4">{p.tiffinId}</td>
                  <td className="py-2 px-4">
                    <button
                      className="text-red-600 hover:underline "
                      onClick={() => handleUpdateClick(p.slug)}
                    >
                      <FcEditImage />
                    </button>
                    <button
                      className=" ml-2text-red-600 hover:underline "
                      onClick={() => handleDel(p._id)}
                    >
                      delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-10 overflow-y-auto"
            initialFocus={cancelButtonRef}
            onClose={() => setOpen(false)}
          >
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="inline-block align-bottom  rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                  <div className="container  mx-auto mt-8 p-4">
                    <div className="bg-white shadow-md rounded-lg p-8 mb-4">
                      <h1 className="text-3xl font-semibold mb-6">
                        Update Item
                      </h1>
                      <div className="w-3/4 mx-auto">
                        <div className="mb-6">
                          <label className="block w-full  border-dashed border-2 border-gray-300 cursor-pointer text-center">
                            <AiOutlineCamera className="w-8 h-8 mx-auto mb-2" />
                            {photo ? photo.name : "Upload Photo"}
                            <input
                              type="file"
                              name="photo"
                              accept="image/*"
                              onChange={(e) => setPhoto(e.target.files[0])}
                              hidden
                            />
                          </label>
                          {photo instanceof File ? (
                            <div className="text-center">
                              <img
                                src={URL.createObjectURL(photo)}
                                alt="item_photo"
                                className="rounded-lg h-md w-md mx-auto"
                              />
                            </div>
                          ) : (
                            <div className="text-center mt-4 h-md w-md">
                              <img
                                src={`http://localhost:5000/api/v1/item/get-photo/${id}`}
                                alt="item_photo"
                                className="rounded-lg mx-auto"
                              />
                            </div>
                          )}
                        </div>

                        {/* start changings */}

                        <div class="sm:col-span-3 mb-4">
                          <label
                            for="category"
                            class="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Select Category
                          </label>
                          <div class="mt-2">
                            <select
                              id="category"
                              name="category"
                              value={category}
                              onChange={(e) => setCategory(e.target.value)}
                              class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                            >
                              <option disabled value="" selected>
                                Select category
                              </option>
                              <option value="veg">Veg</option>
                              <option value="nonveg">Non Veg</option>
                            </select>
                          </div>
                        </div>

                        <div class="sm:col-span-3 mb-4">
                          <label
                            for="thaliType"
                            class="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Thali type
                          </label>
                          <div class="mt-2">
                            <select
                              id="thaliType"
                              name="thaliType"
                              value={thaliType}
                              onChange={(e) => setThaliType(e.target.value)}
                              class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                            >
                              <option disabled selected value="">
                                Select thali
                              </option>
                              <option value="Basic Thali">Basic Thali</option>
                              <option value="Premium Thali">
                                Premium Thali
                              </option>
                              <option value="Special Thali">
                                Special Thali
                              </option>
                              <option value="Deluxe Thali">Deluxe Thali</option>
                            </select>
                          </div>
                        </div>

                        {/* end changing  */}
                        <div className="mb-6">
                          <input
                            type="text"
                            value={name}
                            placeholder="Name"
                            className="block w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>

                        <div className="mb-6">
                          <input
                            type="text"
                            value={tiffinId}
                            placeholder="tiffinId"
                            className="block w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                            onChange={(e) => setTiffinId(e.target.value)}
                          />
                        </div>

                        <div className="mb-6">
                          <input
                            type="number"
                            value={price1}
                            placeholder="Price1"
                            className="block w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                            onChange={(e) => setPrice1(e.target.value)}
                          />
                        </div>
                        <div className="mb-6">
                          <input
                            type="number"
                            value={price2}
                            placeholder="Price2"
                            className="block w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                            onChange={(e) => setPrice2(e.target.value)}
                          />
                        </div>

                        <div className="mb-6">
                          <input
                            type="text"
                            value={description}
                            placeholder="Description"
                            className="block w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                            onChange={(e) => setDescription(e.target.value)}
                          />
                        </div>

                        <div className="flex mb-4">
                          <div className="mr-2">
                            <button
                              className="bg-violet-600 text-white px-2 py-2 rounded-lg hover:bg-violet-800 focus:outline-none focus:ring-2 focus:ring-violet-900"
                              onClick={handleUpdate}
                            >
                              UPDATE ITEM
                            </button>
                          </div>
                          <div>
                            <button
                              className="bg-red-700 text-white px-2 py-2 rounded-lg hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-red-700"
                              onClick={handleDelete}
                            >
                              DELETE ITEM
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
        <Toaster />
      </div>
    </>
  );
};

export default ViewItems;
