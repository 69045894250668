import React, { useEffect, useState } from "react";
import axios from "axios";
import OrderInvoice from "./OrderInvoice";
import toast, { Toaster } from "react-hot-toast";
const AllOrder = () => {
  const [d, setData] = useState([]);
  const [tfdata, setTFD] = useState([]);
  const [userData, setUserData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [delmodal, setdelModal] = useState(false);
  const [selectedBuyer, setSelectedBuyer] = useState("");
  const [selectedOrder, setSelectedOrder] = useState("");
  const [selectedTiffin, setSelectedTiffin] = useState("");
  const [selectedDelboy, setSelectedDelBoy] = useState("");
  const [del, setDel] = useState([]);
  const [isInvoiceModalOpen, setIsInvoiceModalOpen] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [searchId, setSearchId] = useState("");
  const [priya, setPriya] = useState("");
  const getAllOrder = async (id = "") => {
    try {
      const url = id
        ? `/api/v1/payment/get-searchOrder/${id}`
        : "/api/v1/payment/list-orders";
      const res = await axios.get(url);
      console.log(res.data);
      const { data } = res;
      console.log(data, "data all order");
      console.log(Array.isArray(data));
      setData(data);
    } catch (error) {
      console.error(error);
    }
  };

  const getUser = async (id, index) => {
    try {
      const { data } = await axios.get(`/api/v1/auth/get-sUser/${id}`);
      if (data) {
        setUserData((prevUserData) => {
          const updatedUserData = [...prevUserData];
          updatedUserData[index] = data.user;
          return updatedUserData;
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllOrder();
  }, []);

  useEffect(() => {
    // Fetch user data for each item's buyer
    if (Array.isArray(d)) {
      d.forEach((item, index) => {
        getUser(item.buyer, index);
      });
    }
  }, [d]);

  const getAllTf = async () => {
    try {
      const dt = await axios.get("/api/v1/auth/get-alltcenter");
      const { data } = dt;
      console.log(data, "tfdata");
      setTFD(data);
    } catch (error) {
      console.error(error);
    }
  };
  // getting all delivery boy
  const getAlldel = async () => {
    try {
      const del = await axios.get("/api/v1/auth/get-delboy");
      const { data } = del;
      setDel(data);
      console.log(data, "all delivery boy getting successfully...!");
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllTf();
    getAlldel();
  }, []);
  // for tiffin center
  const handleSendButtonClick = (userId, orderId, buyer) => {
    setSelectedOrder(orderId);
    setSelectedBuyer(buyer);
    setModalOpen(true);
  };
  // for delivery boy
  const dropbutton = (userId, orderId, buyer) => {
    setSelectedBuyer(userId);
    setSelectedOrder(orderId);
    setSelectedBuyer(buyer);
    setdelModal(true);
  };

  // for payment
  const paybtn = (userId, orderId, buyer) => {
    // toast.s
    toast.success(orderId);
    toast.success(buyer);
  };
  const handleForwardButtonClick = () => {
    if (selectedBuyer && selectedOrder && selectedTiffin) {
      axios
        .post("/api/v1/tfc/tefor", {
          buser: selectedBuyer,
          orderId: selectedOrder,
          centerId: selectedTiffin,
        })
        .then((response) => {
          console.log("API call successful:", response.data);
          if (!response.data.success) {
            toast.error(response.data.message);
          }
          if (response.data.success) {
            toast.success(response.data.message);
          }
          console.log(response.data.message);
        })
        .catch((error) => {
          console.error("API call error:", error);
        })
        .finally(() => {
          setModalOpen(false);

          setSelectedBuyer("");
          setSelectedOrder("");
          setSelectedTiffin("");
        });
    } else {
      console.error("Incomplete form data");
    }
  };

  const handleClick = (subscription) => {
    setPriya(subscription); // Set the selected order
    setIsInvoiceModalOpen(true);
  };

  // for delboy
  const allotDelboyButtonClick = () => {
    if (selectedBuyer && selectedOrder) {
      axios
        .post("/api/v1/tfc/delor", {
          buyer: selectedBuyer,
          orderId: selectedOrder,
          delId: selectedDelboy,
        })
        .then((response) => {
          console.log("data of all delivery :", response.data);
          if (!response.data.success) {
            toast.error(response.data.message);
          }
          if (response.data.success) {
            toast.success(response.data.message);
          }
          console.log(response.data.message);
        })
        .catch((error) => {
          console.error("error in alloting delivery boy:", error);
        })
        .finally(() => {
          setdelModal(false);

          setSelectedBuyer("");
          setSelectedOrder("");

          setSelectedDelBoy("");
        });
    } else {
      console.error("Incomplete form data");
    }
  };
  const handleSearch = () => {
    getAllOrder(searchId);
  };
  const handleEnterKey = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };
  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const handleSortAscending = () => {
    // Sort data in ascending order based on date
    const sortedData = [...d].sort(
      (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
    );
    setData(sortedData);
  };

  const handleSortDescending = () => {
    // Sort data in descending order based on date
    const sortedData = [...d].sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    );
    setData(sortedData);
  };
  const handleFilterAndSort = () => {
    // Filter data based on the selected date range
    const filteredData = d.filter(
      (item) =>
        new Date(item.createdAt) >= new Date(startDate) &&
        new Date(item.createdAt) <= new Date(endDate)
    );

    // Sort filtered data in ascending order based on date
    const sortedData = [...filteredData].sort(
      (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
    );

    setData(sortedData);
  };
  return (
    <>
      {modalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white w-96 p-8 rounded-lg">
            <h2 className="text-2xl font-bold mb-4 underline text-center">
              Select pincode
            </h2>

            <select
              value={selectedTiffin}
              onChange={(e) => setSelectedTiffin(e.target.value)}
              className="border border-gray-300 p-2 w-full mb-4"
            >
              <option value="">Select an option</option>
              {tfdata.map((item, index) => (
                <React.Fragment key={index}>
                  {item.pincode && (
                    <option value={item._id}>
                      {item.pincode} {item.tiffinName}
                    </option>
                  )}
                  {item.pincode1 && (
                    <option value={item._id}>
                      {item.pincode1} {item.tiffinName}
                    </option>
                  )}
                  {item.pincode2 && (
                    <option value={item._id}>
                      {item.pincode2} {item.tiffinName}
                    </option>
                  )}
                  {item.pincode3 && (
                    <option value={item._id}>
                      {item.pincode3} {item.tiffinName}
                    </option>
                  )}
                  {item.pincode4 && (
                    <option value={item._id}>
                      {item.pincode4} {item.tiffinName}
                    </option>
                  )}
                </React.Fragment>
              ))}
            </select>

            <button
              onClick={() => setModalOpen(false)}
              className="bg-pink-500 text-white py-2 px-4 rounded hover:bg-pink-600"
            >
              Close
            </button>
            <button
              onClick={handleForwardButtonClick}
              className="bg-green-500 text-white py-2 ml-5 px-4 rounded hover:bg-yellow-600"
            >
              Allot
            </button>
          </div>
        </div>
      )}
      {/* this is another model for delivery boy dropdown */}
      {delmodal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white w-96 p-8 rounded-lg">
            <h2 className="text-2xl font-bold mb-4 underline text-center">
              Select delivery
            </h2>

            <select
              value={selectedDelboy}
              onChange={(e) => setSelectedDelBoy(e.target.value)}
              className="border border-gray-300 p-2 w-full mb-4"
            >
              <option value="">Select an option</option>
              {del.map((item, index) => (
                <option key={index} value={item._id}>
                  {item.name}
                </option>
              ))}
            </select>

            <button
              onClick={() => setdelModal(false)}
              className="bg-pink-500 text-white py-2 px-4 rounded hover:bg-pink-600"
            >
              Close
            </button>
            <button
              onClick={allotDelboyButtonClick}
              className="bg-green-500 text-white py-2 ml-5 px-4 rounded hover:bg-yellow-600"
            >
              Allot
            </button>
          </div>
        </div>
      )}
      <h1 className="text-2xl font-bold mb-4 text-center underline text-purple-500">
        All Orders
      </h1>
      <div>
        <div className="m-2">
          <div className="mb-4 flex justify-end">
            <input
              type="text"
              placeholder="search by order Id"
              className="p-2 mr-2 border border-purple-900"
              value={searchId}
              onChange={(e) => setSearchId(e.target.value)}
              onKeyUp={handleEnterKey}
            />
            <button
              className="bg-blue-500 text-white px-4 py-2 rounded"
              onClick={handleSearch}
            >
              Search
            </button>
          </div>

          {/* for date start here  */}
          <div className="mt-4 flex flex-wrap items-center">
            <div>
              {" "}
              <label htmlFor="str" className="font-bold text-purple-500">
                Start Date
              </label>
              <input
                type="date"
                className="m-2 rounded-xl border-2 border-slate-500 "
                value={startDate}
                onChange={handleStartDateChange}
              />
            </div>
            <br />
            <div>
              <label htmlFor="edr" className="font-bold text-purple-500">
                End Date
              </label>
              <input
                type="date"
                className="m-2 rounded-xl border-2 border-slate-500  "
                value={endDate}
                onChange={handleEndDateChange}
              />
            </div>

            <button
              onClick={handleFilterAndSort}
              className="bg-blue-500 text-white py-2 ml-3 mt-2 px-4 rounded hover:bg-yellow-600 w-full md:w-auto"
            >
              Apply
            </button>
          </div>
          {/* Buttons for sorting */}
          <div className="mt-2 flex flex-wrap items-center">
            <button
              onClick={handleSortAscending}
              className="bg-green-500 text-white py-2 ml-3 px-4 rounded hover:bg-yellow-600 w-full md:w-auto"
            >
              Ascending
            </button>

            <button
              onClick={handleSortDescending}
              className="bg-red-500 text-white py-2 ml-3 px-4 rounded hover:bg-yellow-600 w-full md:w-auto mt-2"
            >
              Descending
            </button>
          </div>
          {/* for data end here */}
        </div>
      </div>

      <div className="w-full overflow-x-auto mx-auto bg-white">
        <table className="min-w-full table-auto border-2 border-gray-500">
          <thead className="">
            <tr className="text-center bg-purple-500 text-white">
              <th className="py-2 px-1 border-2 w-auto sm:w-auto md:w-auto lg:w-auto xl:w-auto">
                Date
              </th>
              <th className="py-2 px-1 border-2 w-auto sm:w-auto md:w-auto lg:w-auto xl:w-auto">
                Customer Name
              </th>
              <th className="py-2 px-1 border-2 w-auto sm:w-auto md:w-auto lg:w-auto xl:w-auto">
                Email
              </th>
              <th className="py-2 px-1 border-2 w-auto sm:w-auto md:w-auto lg:w-auto xl:w-auto">
                Address
              </th>
              <th className="py-2 px-1 border-2 w-auto sm:w-auto md:w-auto lg:w-auto xl:w-auto">
                Contact Number
              </th>

              <th className="py-2 px-1 border-2 w-auto sm:w-auto md:w-auto lg:w-auto xl:w-auto">
                Pincode
              </th>

              <th className="py-2 px-1 border-2 w-auto sm:w-auto md:w-auto lg:w-auto xl:w-auto">
                OrderId
              </th>
              <th className="py-2 px-1 border-2 w-auto sm:w-auto md:w-auto lg:w-auto xl:w-auto">
                Tiffiin Schedule
              </th>
              <th className="py-2 px-1 border-2 w-auto sm:w-auto md:w-auto lg:w-auto xl:w-auto">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(d) &&
              d.map((item, index) => (
                <tr
                  key={index}
                  className="font-serif bg-purple-300 text-black border-1"
                >
                  <td className="py-1 text-center">
                    {new Date(item.createdAt).toLocaleDateString("en-GB")}
                  </td>
                  <td className="py-1 text-center">{userData[index]?.name}</td>
                  <td className="py-1 text-center">{userData[index]?.email}</td>
                  <td className="py-1 text-center">
                    {userData[index]?.address}
                  </td>
                  <td className="py-1 text-center">{userData[index]?.phone}</td>
                  <td className="py-1 text-center">
                    {userData[index]?.pincode}
                  </td>
                  <td className="py-1 text-center">{item.razorpay.orderId}</td>
                  <td className="py-1 text-center">
                    {item.item.map((orderItem, itemIndex) => (
                      <div key={itemIndex}>{orderItem.schedule}</div>
                    ))}
                  </td>
                  <td className="py-1 text-center">
                    <button
                      onClick={() =>
                        handleSendButtonClick(
                          userData[index]?.id,
                          item._id,
                          userData[index]?._id
                        )
                      }
                      className=" hover:text-green-500 text-blue-500 py-1"
                    >
                      Allot TF Center
                    </button>
                    <br/>

                    <button
                      onClick={() =>
                        dropbutton(
                          userData[index]?.id,
                          item._id,
                          userData[index]?._id
                        )
                      }
                      className=" hover:text-green-500  text-blue-500 py-1"
                    >
                      Allot Delivery{" "}
                    </button>
                    <br/>
                    <button
                      onClick={() => handleClick(item)}
                      className=" hover:text-green-500  text-blue-500 py-1"
                    >
                      Payment Receipt
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <>
        <OrderInvoice
          isOpen={isInvoiceModalOpen}
          onClose={() => setIsInvoiceModalOpen(false)}
          orderData={priya}
        />
      </>
      <Toaster />
    </>
  );
};

export default AllOrder;
